import axios from 'axios'
import { MainFilterType, filterOption } from '../types/api'
const baseUrl = '/api/v1'
export const axiosClient = axios.create({ baseURL: baseUrl })

export const ApiClient = {
    contacts: {
        /**
         *
         * @param pageParam Page Number
         * @param filters Column Filters
         * @param globalfilters Global Filters
         * @returns A collection of Contacts
         */
        getAll: async (pageParam = 0, filters: any, globalfilters: MainFilterType) => {
            const queryParams = new URLSearchParams()

            filters.forEach((filter: any) => {
                if (filter.value.length > 0 || (typeof filter.value === 'boolean' && filter.value)) {
                    queryParams.append(filter.id, filter.value)
                }
            })

            Object.keys(globalfilters).forEach((key: string) => {
                if (globalfilters[key] && globalfilters[key].length > 0) {
                    if (Array.isArray(globalfilters[key])) {
                        queryParams.append(key, globalfilters[key].join(','))
                    } else {
                        queryParams.append(key, globalfilters[key])
                    }
                }
            })

            let page = pageParam + 1
            let per_page = 50
            try {
                let qs = `/contacts?page=${page}&per_page=${per_page}&${queryParams.toString()}`
                const res = await axiosClient.get(qs)
                return res.data
            } catch (e) {
                console.log(e)
            }
        },
        update: async (data: any) => {
            console.log(data)
            console.log('F', typeof data.functions[0] === 'string')
            if (typeof data.functions[0] !== 'string') {
                data.function = data.functions.map((d: any) => d.name)
            }
            try {
                const res = await axiosClient.put('/contacts/' + data.id, data)
                return res.data
            } catch (error) {
                console.log(error)
            }
        },
        getFunctions: async () => {
            try {
                const res = await axiosClient.get(`/contacts/functions`)
                return res.data.data
            } catch (e) {
                console.log(e)
            }
        },
        getRoleTypes: async (func: string) => {
            let res
            try {
                if (func) {
                    res = await axiosClient.get(`/contacts/role_types/?function=` + func)
                } else {
                    res = await axiosClient.get(`/contacts/role_types/`)
                }

                return res.data.data
            } catch (e) {
                console.log(e)
            }
        },

        getFirmTypes: async () => {
            try {
                const res = await axiosClient.get(`/contacts/firm_types`)
                return res.data.data
            } catch (e) {
                console.log(e)
            }
        },
    },
    assignments: {
        getAll: async () => {
            try {
                const res = await axiosClient.get(`/roles`)
                return res.data.data
            } catch (e) {
                console.log(e)
            }
        },
        addCandidates: async (assignment_id: string, candidates: number[]) => {
            try {
                const res = await axiosClient.post(`/roles/add_candidates`, { role_id: assignment_id, candidates })
                return res.data
            } catch (e) {
                console.log(e)
            }
        },
    },
}
